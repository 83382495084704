import { useState, useEffect } from "react";
import { LeftMenu } from "./LeftMenu/LeftMenu";
import "./MainMenu.css";
import { Market } from "./Market/Market";
import { TopMenu } from "./TopMenu/TopMenu";
import { RightMenu } from "./RightMenu/RightMenu";
import { Document } from "../Components/Document/Document";
import { Registrator } from "./Registrator/Registrator";
import { GetUser } from "../Components/GetUser/GetUser";
import { EditUser } from "../Components/EditUser/EditUser";
import { ContrAgent } from "../Components/ContrAgent/ContrAgent";
import OurOffers from "../Components/OurOffers/OurOffers";

export function MainMenu(props) {
  let [catg, setCatg] = useState("all");
  let [mark, setMark] = useState("Mono");
  let [page, setPage] = useState("0");
  let [pageCount, setPageCount] = useState("0");
  let [load, setLoad] = useState(false);
  let [none, setNone] = useState(false);
  let [leftmenu, setLeftmenu] = useState(false);
  let [rightmenu, setRightmenu] = useState(false);
  let [praducts, setPraducts] = useState([]);
  let [praducts2, setPraducts2] = useState([]);
  let [allpraduct, setAllpraduct] = useState(0);
  let [searche, setSearche] = useState("");
  let [goSearch, setGoSearch] = useState(false);
  let [docsOpen, setDocsOpen] = useState(false);
  let [editOpen, setEditOpen] = useState(false);
  let shx = localStorage.getItem("shaxs");
  let [shaxs, setShaxs] = useState(shx === "jsh" ? true : false);
  let loc = localStorage.getItem("userinfo");
  let [userInfo, setUserInfo] = useState(loc === null ? [] : loc.split(","));
  let [getInfo, setGetInfo] = useState("getType");
  let [proList, setProList] = useState({});
  let [toggleAgent, setToggleAgent] = useState(false);
  let [checkId, setCheckId] = useState();
  let [contrs, setContrs] = useState([]);
  let [loading, setLoading] = useState([]);
  let [newProList, setNewProList] = useState(false);
  let [status, setStatus] = useState("start");
  let [allprice, setAllprice] = useState(0);
  let [loadingForOffer, setLoadingForOffer] = useState(false);
  let [test, setTest] = useState(false);
  let [buyTime, setBuyTime] = useState();
  let [offers, setOffers] = useState(false);
  let [phones, setPhones] = useState([]);
  let [edit, setEdit] = useState(false);

  // document.querySelector('.test-rejim').addEventListener("mouseover",()=>{
  //   console.log('ok');
  // })

  function sendReq(obj) {
    return fetch("https://bot.xorazmelektromarket.uz/GeneralAdmin/post.php/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    });
  }

  function savaDatesForDB(methot, userInfo, Shaxs) {
    let obj = {
      methot: methot,
      userInfo: userInfo,
      userType: Shaxs,
    };

    return sendReq(obj);
  }

  function floor2(sum) {
    let res = sum / 1000;
    res = Math.round(res);
    return res * 1000;
  }

  function resetDates() {
    setGetInfo("getType");
    setDocsOpen(false);
    setEditOpen(false);
    setShaxs(null);
    setUserInfo([]);
    setProList([]);
  }

  useEffect(() => {
    setNone(false);
    let mainUrl = "";
    if (goSearch) {
      mainUrl =
        "https://api.xorazmelektromarket.uz/?searche=" +
        searche.replace("#", "") +
        "&page=" +
        page;
    } else {
      mainUrl =
        "https://api.xorazmelektromarket.uz?catg=" +
        catg +
        "&mark=" +
        mark +
        "&page=" +
        page;
    }

    fetch(mainUrl)
      .then((response) => response.json())
      .then((json) => {
        if (json.erorcode === 404) {
          setNone(true);
          setLoad(false);
          setPageCount(0);
        } else {
          setPraducts(json.body);
          setPageCount(json.count);
          setLoad(true);
        }
        setLoading((prev) => [...prev, true]);
      });
  }, [page, mark, catg, goSearch, searche]);
  // , proList
  useEffect(() => {
    fetch("https://api.xorazmelektromarket.uz/index.php?contrs")
      .then((response) => response.json())
      .then((json) => {
        setContrs(json);
        setLoading((prev) => [...prev, true]);
      });
  }, [userInfo]);

  useEffect(() => {
    setNone(false);
    let mainUrl = "";
    if (goSearch) {
      mainUrl =
        "https://api.xorazmelektromarket.uz/?searche=" +
        searche.replace("#", "") +
        "&page=" +
        page;
    } else {
      mainUrl =
        "https://api.xorazmelektromarket.uz?catg=" +
        catg +
        "&mark=" +
        mark +
        "&page=" +
        page;
    }
    fetch(mainUrl)
      .then((response) => response.json())
      .then((json) => {
        if (json.erorcode === 404) {
          setNone(true);
          setLoad(false);
          setPageCount(0);
        } else {
          setPraducts(json.body);
          setPageCount(json.count);
          setLoad(true);
        }
        setLoading((prev) => [...prev, true]);
      });

    setLoadingForOffer(false);
  }, [loadingForOffer]);

  useEffect(() => {
    if (props.foradmin) {
      fetch("https://api.xorazmelektromarket.uz/index.php?contrs")
        .then((response) => response.json())
        .then((json) => {
          setContrs(json);
          setLoading((prev) => [...prev, true]);
        });
    }
    fetch("https://api.xorazmelektromarket.uz/?phones")
      .then((response) => response.json())
      .then((json) => {
        setPhones(json);
        setLoading((prev) => [...prev, true]);
      });

    fetch("https://api.xorazmelektromarket.uz/?allpraduct=on")
      .then((response) => response.json())
      .then((json) => {
        let prad = [];
        json.forEach((e) => {
          prad[e.pid] = e;
        });
        setPraducts2(prad);
        setLoading((prev) => [...prev, true]);
      });
  }, []);

  let testStyle = test
    ? { right: "0", left: "unset" }
    : { left: "0", right: "unset" };
  return (
    <>
      <div className="matirzayev" onClick={() => props.setPort("none")}>
        <i className="bx bx-code-alt bx-lg"></i>
      </div>
      {offers && (
        <OurOffers
          setOffers={setOffers}
          userInfo={userInfo}
          setProList={setProList}
          setEdit={setEdit}
          sendReq={sendReq}
        />
      )}
      <div
        className="test-rejim"
        style={testStyle}
        onMouseEnter={() => {
          setTest((prev) => !prev);
        }}
      >
        <span>Сайт Тест Жараёнида {props.foradmin && "(Админ)"}</span>
      </div>
      {!(loading[0] && loading[1]) && (
        <div className="loadingPage">
          <h1>
            Малумотлар юкланмокда <li className="bx bx-loader bx-spin"></li>
          </h1>
        </div>
      )}
      <div className="message-pay-check">
        Сизнинг буюртмангиз админга юборилди !
      </div>

      {toggleAgent && (
        <ContrAgent
          setToggleAgent={setToggleAgent}
          setUserInfo={setUserInfo}
          setShaxs={setShaxs}
          contrs={contrs}
          setContrs={setContrs}
        />
      )}
      <Registrator
        getInfo={getInfo}
        setGetInfo={setGetInfo}
        setShaxs={setShaxs}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        shaxs={shaxs}
        setToggleAgent={setToggleAgent}
        savaDatesForDB={savaDatesForDB}
        phones={phones}
        foradmin={props.foradmin}
      />
      {editOpen && (
        <EditUser
          setEditOpen={setEditOpen}
          shaxs={shaxs}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          resetDates={resetDates}
          savaDatesForDB={savaDatesForDB}
          setOffers={setOffers}
        />
      )}
      <div className="mainmenu">
        {docsOpen &&
          (shaxs ? (
            <GetUser
              proList={proList}
              praducts2={praducts2}
              setDocsOpen={setDocsOpen}
              userInfo={userInfo}
              shaxs={shaxs}
              sendReq={sendReq}
              checkId={checkId}
              buyTime={buyTime}
            />
          ) : (
            <Document
              proList={proList}
              praducts2={praducts2}
              setDocsOpen={setDocsOpen}
              userInfo={userInfo}
              shaxs={shaxs}
              sendReq={sendReq}
              checkId={checkId}
              floor2={floor2}
            />
          ))}
        <TopMenu
          setLeftmenu={setLeftmenu}
          leftmenu={leftmenu}
          setRightmenu={setRightmenu}
          rightmenu={rightmenu}
          allpraduct={allpraduct}
          setSearche={setSearche}
          searche={searche}
          setGoSearch={setGoSearch}
          setCatg={setCatg}
          setShaxs={setShaxs}
          setEditOpen={setEditOpen}
          setPage={setPage}
          allprice={allprice}
          setAllprice={setAllprice}
          proList={proList}
          setMark={setMark}
          userInfo={userInfo}
          shaxs={shaxs}
        />
        <div className="mainmenu2">
          <LeftMenu
            catg={catg}
            setCatg={setCatg}
            mark={mark}
            setMark={setMark}
            setLoad={setLoad}
            leftmenu={leftmenu}
            setGoSearch={setGoSearch}
            setPage={setPage}
          />
          <Market
            catg={catg}
            load={load}
            none={none}
            setLoad={setLoad}
            setNone={setNone}
            proList={proList}
            setProList={setProList}
            praducts={praducts}
            setPraducts={setPraducts}
            pageCount={pageCount}
            setPage={setPage}
            page={page}
            shaxs={shaxs}
            newProList={newProList}
            setNewProList={setNewProList}
            status={status}
            setStatus={setStatus}
            floor2={floor2}
          />

          <RightMenu
            rightmenu={rightmenu}
            proList={proList}
            praducts2={praducts2}
            allpraduct={allpraduct}
            setAllpraduct={setAllpraduct}
            setDocsOpen={setDocsOpen}
            shaxs={shaxs}
            sendReq={sendReq}
            userInfo={userInfo}
            checkId={checkId}
            setCheckId={setCheckId}
            setNewProList={setNewProList}
            status={status}
            setStatus={setStatus}
            allprice={allprice}
            setAllprice={setAllprice}
            setProList={setProList}
            setCatg={setCatg}
            setLoading={setLoading}
            loadingForOffer={loadingForOffer}
            setLoadingForOffer={setLoadingForOffer}
            floor2={floor2}
            setBuyTime={setBuyTime}
            edit={edit}
            setEdit={setEdit}
          />
        </div>
      </div>
    </>
  );
}
