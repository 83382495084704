import { useEffect, useState } from "react";
import "./RightMenu.css";
import { PayCheck } from "../../Components/PayCheck/PayCheck";

export function RightMenu(props) {
  function upPrice(sumShax, sum) {
    let pra = (Number(sum) / 100) * 12;
    if (sumShax) {
      // return floor2(Number(sum) + Number(pra));
      return floor2(Number(sum) / 0.92);
    } else {
      return Number(sum);
    }
  }
  let {
    proList,
    praducts2,
    allpraduct,
    setAllpraduct,
    newProList,
    setNewProList,
    status,
    setStatus,
    allprice,
    setAllprice,
    setProList,
    setCatg,
    setLoading,
    floor2,
    setBuyTime,
    edit,
    setEdit,
  } = props;

  let keys = Object.keys(proList);
  let shaxsShart = props.shaxs !== true && props.shaxs !== null;
  useEffect(() => {
    let allpraduct = 0;
    let allprice = 0;
    keys.map((a) => {
      allprice +=
        upPrice(shaxsShart, Number(praducts2[a]["price"].replace(".", ""))) *
        proList[a];
      allpraduct += Number(proList[a]);
    });
    setAllpraduct(allpraduct);
    setAllprice(allprice);
  }, [proList]);
  return (
    <div className={`rightmenu ${props.rightmenu && "open"}`}>
      {shaxsShart ? (
        <table className="clientInfo">
          <tfoot>
            {" "}
            <tr className="nameCli">
              <td>Бюртмачи:</td>
              <td className="text-right">{props.userInfo[2]}</td>
            </tr>
            <tr className="telCli">
              <td>Телефон ракамлар:</td>
              <td className="text-right">{props.userInfo[1]}</td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <table className="clientInfo">
          <tfoot>
            <tr className="nameCli">
              <td>Бюртмачи:</td>
              <td className="text-right">{props.userInfo[0]}</td>
            </tr>
            <tr className="telCli">
              <td>Телефон ракамлар:</td>
              <td className="text-right">{props.userInfo[1]}</td>
            </tr>
          </tfoot>
        </table>
      )}
      <div className="main-btn">:Ройхат</div>
      <div className="list-body">
        {keys.map((a, i) => (
          <div
            className={`list-item row ${
              Number(praducts2[a]["vse"]) < 0 ||
              Number(praducts2[a]["vse"]) === 0
                ? "priceNol"
                : ""
            }`}
            key={a}
          >
            <div className="item-price col-5">
              {upPrice(shaxsShart, praducts2[a]["price"])}
            </div>
            <div className="item-count col-2">x{proList[a]}</div>
            <div className="item-name col-5">
              <span>{i + 1 + ". "}</span>
              {praducts2[a]["name"]}
            </div>
          </div>
        ))}
        {keys.length > 0 ? (
          <>
            <div className="list-footer row">
              <div className="item-allprice col-5">
                {allprice.toLocaleString("en-US")} сўм
              </div>
              <div className="item-allpraduct col-2">{allpraduct}</div>
              <div className="item-alltype col-5">:Жами</div>
            </div>
            <PayCheck
              setDocsOpen={props.setDocsOpen}
              shaxs={props.shaxs}
              proList={props.proList}
              allprice={allprice}
              sendReq={props.sendReq}
              userInfo={props.userInfo}
              checkId={props.checkId}
              setCheckId={props.setCheckId}
              newProList={newProList}
              setNewProList={setNewProList}
              status={status}
              setStatus={setStatus}
              setProList={setProList}
              setCatg={setCatg}
              setLoading={setLoading}
              loadingForOffer={props.loadingForOffer}
              setLoadingForOffer={props.setLoadingForOffer}
              floor2={floor2}
              setBuyTime={setBuyTime}
              edit={edit}
              setEdit={setEdit}
            />
            <div className="support">
              <div>Оператор билан богланиш:</div>
              <div>
                <i className="bx bx-support"></i>
                <a href="tel:+998991649990">+998991649990</a>
              </div>
            </div>
          </>
        ) : (
          <div className="item-text-center">! Ройхат бош</div>
        )}
      </div>
    </div>
  );
}

{
  /*
 <div
className="docs-open-btn"
onClick={() => {
  props.setDocsOpen((prev) => !prev);
}}
>
{props.shaxs ? "Чекни очиш" : "Хисоб варака очиш"}
</div>
 */
}
