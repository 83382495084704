function Translate(text) {
  var replace = [
    "ts",
    "o‘",
    "O‘",
    "G‘",
    "g‘",
    "'",
    "'",
    "sh",
    "Sh",
    "ch",
    "Ch",
    "yo",
    "Yo",
    "ya",
    "Ya",
    "yu",
    "Yu",
    " Ye",
    " ye",
    "Q",
    "q",
    "E",
    "e",
    "R",
    "r",
    "T",
    "t",
    "Y",
    "y",
    "U",
    "u",
    "I",
    "i",
    "O",
    "o",
    "P",
    "p",
    "A",
    "a",
    "S",
    "s",
    "D",
    "d",
    "F",
    "f",
    "G",
    "g",
    "H",
    "h",
    "J",
    "j",
    "K",
    "k",
    "L",
    "l",
    "Z",
    "z",
    "X",
    "x",
    "V",
    "v",
    "B",
    "b",
    "N",
    "n",
    "M",
    "m",
    "C",
    "c",
  ];

  var find = [
    "ц",
    "ў",
    "Ў",
    "Ғ",
    "ғ",
    "ъ",
    "Ъ",
    "ш",
    "Ш",
    "ч",
    "Ч",
    "ё",
    "Ё",
    "я",
    "Я",
    "ю",
    "Ю",
    " Е",
    " е",
    "Қ",
    "қ",
    "Э",
    "э",
    "Р",
    "р",
    "Т",
    "т",
    "Й",
    "й",
    "У",
    "у",
    "И",
    "и",
    "О",
    "о",
    "П",
    "п",
    "А",
    "а",
    "С",
    "с",
    "Д",
    "д",
    "Ф",
    "ф",
    "Г",
    "г",
    "Ҳ",
    "ҳ",
    "Ж",
    "ж",
    "К",
    "к",
    "Л",
    "л",
    "З",
    "з",
    "Х",
    "х",
    "В",
    "в",
    "Б",
    "б",
    "Н",
    "н",
    "М",
    "м",
    "С",
    "с",
  ];
  var str = text;
  for (var i = 0; i < find.length; i++) {
    str = str.replace(replace[i], find[i]);
  }
  return str;
}

function Searche(arr, term) {
  if (term.lenght === 0) {
    return arr;
  }

  return arr.filter(
    (item) =>
      Translate(
        item.contrId === null
          ? item.baseID + " " + item.contrName
          : item.contrId + " " + item.contrName
      )
        .toLowerCase()
        .indexOf(term) > -1
  );
}

function FilterMode(arr, filmode) {
  if (filmode === "ПП") {
    return arr.filter((item) => item.contrCatg === "ПП");
  } else if (filmode === "Пере") {
    return arr.filter((item) => item.contrCatg === "Пере");
  } else if (filmode === "П") {
    return arr.filter((item) => item.contrCatg === "П");
  } else if (filmode === "БС") {
    return arr.filter((item) => item.contrCatg === "БС");
  } else if (filmode === "Ю") {
    return arr.filter((item) => item.contrCatg === "Ю");
  } else if (filmode === "Э") {
    return arr.filter((item) => item.contrCatg === "Э");
  } else if (filmode === "Ф") {
    return arr.filter((item) => item.contrCatg === "Ф");
  } else if (filmode === "Д") {
    return arr.filter((item) => item.contrCatg === "Д");
  } else {
    return arr;
  }
}

export { Translate, FilterMode, Searche };
