import { useState } from "react";
import "./MenuBulder.css";

export function MenuBuilder(props) {
  // console.log(props);
  let [target, setTarget] = useState(false);
  return (
    <div className="menubuilder">
      <button
        className="btn"
        onClick={() => {
          setTarget((q) => !q);
        }}
      >
        <span>{props.listId + 1 + ".  " + props.acKey}</span>
        <i className={`bx bx-chevron-down ${target && "active"}`}></i>
      </button>
      <ul className={`acardion  ${target && "active"}`}>
        {props.acArrKeys.map((a, i) => {
          return (
            <li key={i}>
              <div
                className={`catg-name ${props.catg === a && "select"}`}
                onClick={() => {
                  props.setCatg(a);
                  props.setPage("0");
                  props.setLoad(false);
                  props.setGoSearch(false);
                }}
              >
                {props.acArrNames[i]}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
