import { useEffect, useState } from "react";
import "./Products.css";
import { EditTime } from "../../functions/dataTime";
import { Pricing } from "../../functions/mathFunctions";

export default function Products(props) {
  let [listType, setListType] = useState();
  let { setNowStep, prods, praducts } = props;
  let result = Pricing(praducts, prods.ofdata);
  function formatOurOffer(pr, type = 1) {
    let res = "";
    if (type === 1) {
      if (pr === null) {
        res = " Жонатилган";
      } else if (pr === "0") {
        res = " Кабул килинган";
      } else if (pr === "1") {
        res = " Тугалланган";
      }
      return res;
    }
  }

  function setPraductsInWin(praducts2 = "") {
    let ok = [];
    for (const key in prods.ofdata) {
      let pros = praducts[key];
      let pic =
        pros.pic === null
          ? ",https://matirzayev.uz/mainFiles/logo.png"
          : pros.pic;
      ok.push(
        <>
          <div className="card">
            <img className="pic" src={pic.split(",")[1]} alt="" />
            <span className="price">Номи: {pros.name}</span>
            <span className="price">Нархи: {pros.price}</span>
            <span className="ptype">Ўлчов: {pros.ptype}</span>
            <span className="ofdata">Умумий сони: {prods.ofdata[key]}</span>
          </div>
        </>
      );
    }

    return ok;
    // for (const key in prods.ofdata) {
    //   console.log(key);
    //   let pros = praducts[key];
    //   let pic =
    //     pros.pic === null
    //       ? ",https://matirzayev.uz/mainFiles/logo.png"
    //       : pros.pic;
    //   return (
    //     <>
    //       <div className="card">
    //         <img className="pic" src={pic.split(",")[1]} alt="" />
    //         <span className="price">Нархи: {pros.price}</span>
    //         <span className="ptype">Ўлчов: {pros.ptype}</span>
    //         <span className="ofdata">Умумий сони: {prods.ofdata[key]}</span>
    //       </div>
    //     </>
    //   );
    // }
  }
  function pres(a) {
    setNowStep("offer");
  }
  function getTableProdFunc() {
    let arr = [];
    let i = 0;
    for (let key in result.prod) {
      i++;
      arr.push(
        <tr>
          <td className="tar">{i}</td>
          <td>{praducts[key]["pid"]}</td>
          <td>{}</td>
          <td>{result.prod[key].name}</td>
          <td className="tar">{result.prod[key].count}</td>
          <td className="tar">{result.prod[key].ptype}</td>
          <td className="tar">{result.prod[key].singlePrice}</td>
          <td className="tar">{result.prod[key].price}</td>
          <td className="tar">
            {prods.ofshaxs === "1" ? result.prod[key].qqs : "-"}
          </td>
        </tr>
      );
    }

    return arr;
  }

  useEffect(() => {
    document.addEventListener("keydown", pres, false);
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener("keydown", pres, false);
    };
  });

  return (
    <div className="Products">
      <div className="prodMain">
        <div
          className="backOutMenu"
          onClick={() => {
            setNowStep("offer");
          }}
        >
          <i className="bx bx-x bx-lg"></i>
        </div>
        <div className="prodTopBar">
          <div className="textContent">
            <h1>Buyurtma haqida ma'lumot</h1>
          </div>
          <div className="infoContent">
            <ul>
              <li>
                <span className="li-name">Буюрмачи:</span>
                <span className="li-value">{prods.ofname} </span>
              </li>
              <li>
                <span className="li-name">Телефон раками:</span>
                <span className="li-value">{prods.ofnumber} </span>
              </li>
              <li>
                <span className="li-name">Буюрма вакти:</span>
                <span className="li-value">
                  {EditTime(Number(prods.oftime + "000"))}
                </span>
              </li>
              <li>
                <span className="li-name">Буюрма холати:</span>
                <span className="li-value">{formatOurOffer(prods.ofnow)}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="settingsBtnListProd">
          <div className="listTypeBtn" onClick={() => setListType((a) => !a)}>
            Ройхат кориниши:{" "}
            {listType ? (
              <i className="bx bx-images bx-md"></i>
            ) : (
              <i className="bx bx-list-ol bx-md"></i>
            )}
          </div>
          <a
            className="listTypeBtn"
            target="_blank"
            href={
              `https://bot.xorazmelektromarket.uz/GeneralAdmin/exsel.php?id=` +
              prods.ofid
            }
          >
            Exsel <i class="bx bx-export"></i>
          </a>
        </div>
        {listType ? (
          <div className="prodContent">{setPraductsInWin()}</div>
        ) : (
          <div className="prodTable">
            <table>
              <thead>
                <tr>
                  <td>№</td>
                  <td>Код</td>
                  <td>Артикул</td>
                  <td>Товар</td>
                  <td colSpan={2}>Количества</td>
                  <td>Цена</td>
                  <td>Сумма</td>
                  <td>QQS</td>
                </tr>
              </thead>
              <tbody>{getTableProdFunc()}</tbody>
              <tfoot>
                <tr>
                  <td colSpan={4}>Жами:</td>
                  <td className="tar">{result.count}</td>
                  <td></td>
                  <td></td>
                  <td className="tar">{result.price}</td>
                  <td className="tar">{result.qqs}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
