import { useState, useEffect } from "react";
import "./PayCheck.css";

export function PayCheck(props) {
  let {
    shaxs,
    setDocsOpen,
    checkId,
    setCheckId,
    proList,
    sendReq,
    allprice,
    userInfo,
    newProList,
    setNewProList,
    status,
    setStatus,
    setProList,
    setCatg,
    setLoading,
    loadingForOffer,
    setLoadingForOffer,
    setBuyTime,
    edit,
    setEdit,
  } = props;

  let [timer, setTimer] = useState(15);
  let [inter, setInter] = useState(false);

  let matn = {
    start: "! Тўлов қилиш",
    check: "! Текшириш",
    checking: (
      <>
        Текширилмокдв{""} 00:{timer} <i className="bx bx-loader bx-spin"></i>
      </>
    ),
    eror: "Хатолик",
  };

  useEffect(() => {
    if (status !== "check") {
      if (inter === true) {
        let int = setInterval(() => {
          fetch("https://api.xorazmelektromarket.uz/?checkpay=" + checkId)
            .then((response) => response.json())
            .then((json) => {
              if (json.check) {
                setStatus("accect");
                clearInterval(int);
                setInter(false);
                setBuyTime(new Date().getTime());
              }
            });
          setTimer((p) => {
            if (p > 0) {
              return p - 1;
            } else {
              setStatus("eror");
              setInter(false);
            }
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(int);
        }, 16000);
      }
    } else {
      setStatus("start");
    }
  }, [proList, inter, checkId]);
  return (
    <>
      {status === "accect" ? (
        <div
          className="docs-open-btn"
          onClick={() => {
            props.setDocsOpen((prev) => !prev);
          }}
        >
          {props.shaxs ? "Чекни очиш" : "Хисоб варака очиш"}
        </div>
      ) : (
        <div className="pay-check-button">
          {status === "check" && (
            <a href="https://matirzayev.uz/card/" target="_">
              <i className="bx bxs-credit-card bx-lg"></i>
            </a>
          )}
          <span
            onClick={() => {
              setStatus((p) => {
                if (p === "start") {
                  setNewProList(true);
                  return "check";
                } else if (p === "check") {
                  sendReq({
                    methot: edit === false ? "checkcard" : "editProdList",
                    price: allprice,
                    info: userInfo,
                    list: proList,
                    id: "no",
                    shaxs: shaxs,
                    type: "pays",
                    offerID: edit,
                  })
                    .then((json) => json.json())
                    .then((res) => {
                      setTimer(15);
                      setInter(true);
                      setCheckId(res[0]);
                      setLoadingForOffer(true);
                    });
                  setEdit(false);

                  return "checking";
                } else if (p === "eror") {
                  sendReq({
                    methot: edit === false ? "checkcard" : "editProdList",
                    price: allprice,
                    info: userInfo,
                    list: proList,
                    id: checkId,
                    shaxs: shaxs,
                    type: "pays",
                    offerID: edit,
                  })
                    .then((json) => json.json())
                    .then((res) => {
                      setTimer(15);
                      setInter(true);
                      setCheckId(res[0]);
                      setLoadingForOffer(true);
                    });
                  setEdit(false);
                  return "checking";
                } else if (p === "checking") {
                  return "checking";
                }
              });
            }}
          >
            {matn[status]}
          </span>
          {status === "start" && (
            <span
              className="offer"
              onClick={() => {
                sendReq({
                  methot: edit === false ? "offer" : "editProdListBuy",
                  price: allprice,
                  info: userInfo,
                  list: proList,
                  shaxs: shaxs,
                  type: "tezkor",
                  offerID: edit,
                });
                setEdit(false);
                setProList([]);
                setNewProList(false);
                setStatus("start");
                setCatg("all");
                // setLoading([]);
                setLoadingForOffer(true);
                // active-paychack
                let message = document.querySelector(".message-pay-check");
                message.classList.add("active-paychack");
                setTimeout(() => {
                  message.classList.remove("active-paychack");
                }, 4000);
              }}
            >
              Буюртма бериш
            </span>
          )}
        </div>
      )}
    </>
  );
}
