import { useState, useEffect } from "react";
import "./AdminMenu.css";
import Offers from "./Offers/Offers";
import Products from "./Products/Products";

export default function AdminMenu(props) {
  let [nowStep, setNowStep] = useState("offer");
  let [offerId, setOfferId] = useState();
  let [prods, setProds] = useState([]);
  let [praducts, setPraducts] = useState();
  let [loader, setLoader] = useState(false);

  let { setPort, setForadmin } = props;

  useEffect(() => {
    setLoader(true);
    fetch("https://api.xorazmelektromarket.uz/?allpraduct=on")
      .then((response) => response.json())
      .then((json) => {
        let prad = [];
        json.forEach((e) => {
          prad[e.pid] = e;
        });
        setPraducts(prad);
        setLoader(false);
      });
  }, []);
  return (
    <>
      {loader ? (
        <div className="adminLoading">
          <span>Юкланмокда !</span> <i className="bx bx-loader bx-spin"></i>
        </div>
      ) : (
        <>
          {nowStep === "offer" && (
            <Offers
              setNowStep={setNowStep}
              setOfferId={setOfferId}
              setProds={setProds}
            />
          )}
          {nowStep === "product" && (
            <Products
              offerId={offerId}
              setNowStep={setNowStep}
              prods={prods}
              praducts={praducts}
            />
          )}
          <div className="matirzayev" onClick={() => setPort("none")}>
            <i className="bx bx-code-alt bx-lg"></i>
          </div>{" "}
          <div
            className="matirzayev2"
            onClick={() => {
              setPort("user");
              setForadmin(true);
            }}
          >
            Заказ олиш
          </div>
        </>
      )}
    </>
  );
}
