import { useEffect, useState } from "react";
import { EditTime } from "../../functions/dataTime";
import "./OurOffers.css";

export default function OurOffers(props) {
  let { setOffers, userInfo, sendReq, setProList, setEdit } = props;
  let [loader, setLoader] = useState(true);
  let [loaderOn, setLoaderOn] = useState(true);
  let [offerList, setOfferList] = useState([]);
  let [mes, setMes] = useState(false);
  useEffect(() => {
    setLoader(true);
    fetch("https://api.xorazmelektromarket.uz/?offers=" + userInfo[10])
      .then((response) => response.json())
      .then((json) => {
        setOfferList(json);
        setLoader(false);
      });
  }, [loaderOn]);

  function formatOurOffer(pr, type = 1) {
    let res = [];
    if (type === 1) {
      if (pr === null) {
        res = ["Жўнатилган", "jn"];
      } else if (pr === "0") {
        res = ["Кабул килинган", "qn"];
      } else if (pr === "1") {
        res = ["Тугалланган", "tn"];
      }
    }

    return res;
  }

  return (
    <div
      className="OurOffers"
      onDoubleClick={() => {
        setOffers((a) => !a);
      }}
    >
      <div className="result-bar-offer">
        <div className={`message-result-bar ${mes && "viewMes"}`}>
          Сиз бу бюртмани кабул клингани учун ўзгартира олмайсиз.
        </div>
        <div className="fixed-btns">
          <div className="reload-btn" onClick={() => setLoaderOn((a) => !a)}>
            <i className="bx bx-refresh bx-lg bx-spin-hover"></i>
          </div>
        </div>
        <div className="text-offer">
          <h1>Сизнинг бюртмаларингиз!</h1>
        </div>
        <div className="filter-line"></div>
        <div className="result-offer">
          <ul>
            {loader ? (
              <div className="loader">
                Юкланмокда <i className="bx bx-loader bx-spin"></i>
              </div>
            ) : offerList.length < 0 ? (
              <div className="none-offer">Сзда хали бюртма мавжуд емас !</div>
            ) : (
              offerList.map((key, i) => {
                let result = formatOurOffer(key.ofnow);
                return (
                  <li key={i}>
                    <span className="odd">{i + 1}.</span>
                    <span className="id">#00-{key.ofid.padStart(6, "0")}</span>
                    <span className="time">
                      {EditTime(Number(key.oftime + "000"))}
                    </span>
                    <span
                      className={`type ${result[1]}`}
                      onClick={() => {
                        if (key.ofnow === "0" || key.ofnow === "1") {
                          setMes(true);
                          setTimeout(() => {
                            setMes(false);
                          }, 2000);
                        } else {
                          sendReq({ id: key.ofid, methot: "editPro" });
                          setProList(JSON.parse(key.ofdata));
                          setOffers(false);
                          setEdit(key.ofid);
                        }
                      }}
                    >
                      {result[0]}
                    </span>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
