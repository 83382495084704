import { useEffect, useState } from "react";
import { EditTime } from "../../functions/dataTime";
import "./Offers.css";

export default function Offers(props) {
  let [loader, setLoader] = useState(true);
  let [loaderOn, setLoaderOn] = useState(true);
  let [offerList, setOfferList] = useState([]);
  let [sortBy, setSortBy] = useState("ofname");
  let [sortType, setSortType] = useState("ASC");
  // let [typeOfNameTime, setTypeOfNameTime] = useState(true);

  let { setOfferId, setNowStep, setProds } = props;
  useEffect(() => {
    let url =
      "https://api.xorazmelektromarket.uz/?offerBy=" +
      sortBy +
      "&offerType=" +
      sortType;

    setLoader(true);
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        setOfferList(json);
        setLoader(false);
      });
  }, [loaderOn, sortType, sortBy]);

  function formatOurOffer(pr, type = 1) {
    let res = [];
    if (type === 1) {
      if (pr === null) {
        res = ["Жонатилган", "jn"];
      } else if (pr === "0") {
        res = ["Кабул килинган", "qn"];
      } else if (pr === "1") {
        res = ["Тугалланган", "tn"];
      }
    }

    return res;
  }

  return (
    <div className="Offers">
      <div className="result-bar-offer">
        <div className="fixed-btns">
          <div className="reload-btn" onClick={() => setLoaderOn((a) => !a)}>
            <i className="bx bx-refresh bx-lg bx-spin-hover"></i>
          </div>
        </div>
        <div className="text-offer">
          <h1>Буюрмалар !</h1>
        </div>
        <div className="filter-line"></div>
        <div className="result-offer">
          <ul>
            <li>
              <span className="odd">#</span>
              {/* <span className="id" onClick={() => setTypeOfNameTime((a) => !a)}>
                {typeOfNameTime ? "ID" : "Буюрма вакти"}
              </span> */}
              <span
                className="id"
                onClick={() => {
                  setSortBy("oftime");
                  setSortType((a) => (a !== "ASC" ? "ASC" : "DESC"));
                }}
              >
                Буюрма вакти
              </span>
              <span
                className="time"
                onClick={() => {
                  setSortBy("ofname");
                  setSortType((a) => (a !== "ASC" ? "ASC" : "DESC"));
                }}
              >
                Бюртмачи
              </span>
              <span
                className="type"
                onClick={() => {
                  setSortBy("ofnow");
                  setSortType((a) => (a !== "ASC" ? "ASC" : "DESC"));
                }}
              >
                Холати
              </span>
            </li>
            {loader ? (
              <div className="loader">
                Юкланмокда <i className="bx bx-loader bx-spin"></i>
              </div>
            ) : offerList.length < 0 ? (
              <div className="none-offer">Буюрма мавжуд эмас</div>
            ) : (
              offerList.map((key, i) => {
                let result = formatOurOffer(key.ofnow);
                return (
                  <li
                    key={i}
                    onClick={() => {
                      setProds({
                        ...key,
                        ofdata: JSON.parse(key.ofdata),
                      });
                      setNowStep("product");
                    }}
                  >
                    <span className="odd">{i + 1}.</span>
                    {/* <span className="id">#00-{key.ofid.padStart(6, "0")}</span> */}
                    {/* {EditTime(Number(key.oftime + "000"))} */}
                    {/* {typeOfNameTime ? (
                      <span className="id">
                        #00-{key.ofid.padStart(6, "0")}
                      </span>
                    ) : (
                      <span className="id">
                        {EditTime(Number(key.oftime + "000"), 1)}
                      </span>
                    )} */}
                    <span className="id">
                      {EditTime(Number(key.oftime + "000"), 1)}
                    </span>
                    <span className="time">{key.ofname}</span>
                    <span className={`type ${result[1]}`}>{result[0]}</span>
                  </li>
                );
              })
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
