import { GetInfo } from "../../Components/GetInfo/GetInfo";
import "./Registrator.css";

export function Registrator(props) {
  let {
    getInfo,
    setGetInfo,
    setShaxs,
    userInfo,
    setUserInfo,
    shaxs,
    phones,
    foradmin,
  } = props;
  return (
    <>
      {userInfo[0] === undefined && (
        <div className="shaxs">
          {getInfo === "getType" ? (
            <div className="shaxs-box">
              <span>шахс турини танланг:</span>
              <div className="btns">
                <div
                  className="yes"
                  onClick={() => {
                    setShaxs(true);
                    setGetInfo("");
                    localStorage.setItem("shaxs", "jsh");
                  }}
                >
                  Жисмоний
                </div>
                <div
                  className="no"
                  onClick={() => {
                    setShaxs(false);
                    setGetInfo("getYur");
                    localStorage.setItem("shaxs", "ysh");
                  }}
                >
                  Юридик
                </div>
              </div>
              {foradmin ? (
                <div
                  className="logIn"
                  onClick={() => props.setToggleAgent(true)}
                >
                  Олдин хам фойдаланганман
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <GetInfo
              phones={phones}
              setGetInfo={setGetInfo}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              shaxs={shaxs}
              setShaxs={setShaxs}
              savaDatesForDB={props.savaDatesForDB}
            />
          )}
        </div>
      )}
    </>
  );
}
