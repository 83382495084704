import { useState } from "react";
import { MainMenu } from "./MainMenu/MainMenu";
import AdminMenu from "./AdminMenu/AdminMenu";
import "./Main.css";

export default function Main() {
  let [port, setPort] = useState("user");
  let [sup, setSup] = useState(false);
  let [user, setUser] = useState("");
  let [pass, setPass] = useState("");
  let [eror, setEror] = useState(false);
  let [foradmin, setForadmin] = useState(false);
  return (
    <>
      {port === "admin" && (
        <AdminMenu setPort={setPort} setForadmin={setForadmin} />
      )}
      {port === "user" && <MainMenu setPort={setPort} foradmin={foradmin} />}
      {port === "none" && (
        <div className="mainPanel">
          {sup ? (
            <div className="supportPanel">
              <h2>Малумотларни толдиринг</h2>
              {eror && (
                <div className="erorMessagePanel">Усер ёки парол нотўгри !</div>
              )}

              <label htmlFor="user">
                <input
                  placeholder="User"
                  className="user"
                  type="text"
                  value={user}
                  onChange={(a) => setUser(a.target.value)}
                />
              </label>
              <label htmlFor="pass">
                <input
                  placeholder="Password"
                  className="pass"
                  type="password"
                  value={pass}
                  onChange={(a) => setPass(a.target.value)}
                  onKeyDown={(a) => {
                    if (pass === "admin" && user === "admin") {
                      setPort("admin");
                      setSup(false);
                      setPass("");
                      setUser("");
                      setEror(false);
                    } else {
                      setEror(true);
                    }
                  }}
                />
              </label>
              <div
                className="supBtnPanel"
                onClick={() => {
                  if (pass === "admin" && user === "admin") {
                    setPort("admin");
                    setSup(false);
                    setPass("");
                    setUser("");
                    setEror(false);
                  } else {
                    setEror(true);
                  }
                }}
              >
                Кириш
              </div>
            </div>
          ) : (
            <div className="containerPanel">
              <h1>Фойдаланувчи турини танланг</h1>
              <div className="btns">
                {" "}
                <div className="btn" onClick={() => setSup((a) => !a)}>
                  admin
                </div>
                <div className="btn" onClick={() => setPort("user")}>
                  user
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
