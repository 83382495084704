import { useState } from "react";
import "./ContrAgent.css";
import { Translate, FilterMode, Searche } from "../../functions/textFunctions";

export function ContrAgent(props) {
  let { setToggleAgent, contrs, setUserInfo, setShaxs } = props;
  let [term, setTerm] = useState("");
  let [filmode, setFilmode] = useState("");

  return (
    <>
      <div className="contrAgent">
        <div
          className="close-btn"
          onClick={() => setToggleAgent((p) => !p)}
        ></div>

        <div className="work-box">
          <div className="searche-bar">
            <input
              type="text"
              onChange={(a) => {
                setTerm(a.target.value);
              }}
              value={term}
            />
            <div className="searche-btn">Qidirish</div>
          </div>
          <div className="setting-bar">
            <span
              onClick={() => setFilmode((prev) => (prev === "Ю" ? "all" : "Ю"))}
              className={`${filmode === "Ю" ? "active" : ""}`}
            >
              Юридиклар
            </span>
            <span
              onClick={() => setFilmode((prev) => (prev === "Д" ? "all" : "Д"))}
              className={`${filmode === "Д" ? "active" : ""}`}
            >
              Даставка
            </span>
            <span
              onClick={() => setFilmode((prev) => (prev === "Э" ? "all" : "Э"))}
              className={`${filmode === "Э" ? "active" : ""}`}
            >
              Эликтриклар
            </span>
            <span
              onClick={() => setFilmode((prev) => (prev === "Ф" ? "all" : "Ф"))}
              className={`${filmode === "Ф" ? "active" : ""}`}
            >
              Фукаролар
            </span>
          </div>
          <div className="result-bar no-scroll  ">
            {/* term.charAt(0) + term.charAt(1) === "  "  */}
            {true ? (
              <ul>
                {Searche(
                  FilterMode(contrs, filmode),
                  Translate(term.replace("  ", "").toLowerCase())
                )[0] !== undefined ? (
                  Searche(
                    FilterMode(contrs, filmode),
                    Translate(term.replace("  ", "").toLowerCase())
                  ).map((item, i) => {
                    return (
                      <li
                        className={`${item.actype}`}
                        key={i}
                        onClick={() => {
                          let massiv = [
                            item.contrName,
                            item.phone,
                            item.contrName,
                            item.adress,
                            item.contrInn,
                            item.kks,
                            item.bank,
                            item.mfo,
                            item.raxbar,
                            item.bugalter,
                            item.baseID,
                          ];
                          setUserInfo(massiv);
                          localStorage.setItem("userinfo", massiv);

                          setShaxs(item.contrType === "0");
                          localStorage.setItem(
                            "shaxs",
                            item.contrType === "0" ? "jsh" : "ysh"
                          );

                          setToggleAgent((p) => !p);
                        }}
                      >
                        <span>
                          {item.contrId === null ? item.baseID : item.contrId}
                        </span>{" "}
                        <span>
                          {item.contrName.replace(
                            item.contrId === null ? item.baseID : item.contrId,
                            ""
                          )}
                        </span>
                      </li>
                    );
                  })
                ) : (
                  <span className="contr-eror">
                    Бундай фойдаланувчи мавжуд емас
                  </span>
                )}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
