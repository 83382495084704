import "./Card.css";
import { UpPrice, FormatNum, Floor2 } from "../../functions/mathFunctions";

export function Card(props) {
  let {
    mainArr,
    setSelect,
    setFonUrl,
    setPname,
    setPdecs,
    setProList,
    proList,
    shaxs,
  } = props;

  let praductID = mainArr.pid,
    praductName = mainArr.name,
    praductPic =
      mainArr.pic === null
        ? ",https://matirzayev.uz/mainFiles/logo.png"
        : mainArr.pic,
    praductDec = mainArr.dessc,
    praductNoPrice = mainArr.noprice,
    praductPrice =
      shaxs !== true && shaxs != null ? UpPrice(mainArr.price) : mainArr.price,
    praductPtype = mainArr.ptype === "шт" ? "дона" : mainArr.ptype;

  return (
    <div className="Card col-3-lg col-6-md col-12-sm">
      <div className="Card-child">
        <div
          className="image"
          onClick={() => {
            setSelect(praductID);
            setFonUrl(praductPic.split(","));
            setPname(praductName);
            setPdecs(praductDec);
          }}
        >
          <img
            src={praductPic.split(",")[1]}
            alt={praductName}
            className={mainArr.pic === null ? "imageNotFount" : ""}
          />
        </div>
        <div className="decs">
          <h3>
            {praductName}
            <span
              className="praduct_id"
              onClick={(a) => {
                navigator.clipboard.writeText(a.target.innerText);
              }}
            >
              #{praductID}
            </span>
          </h3>
          <p>{praductDec}</p>
        </div>
        <>
          <div className="card-footer">
            <div className="noprice">{praductNoPrice}</div>
            <div
              className={`vse ${
                Number(mainArr.vse) < 0 || Number(mainArr.vse) === 0
                  ? "nol"
                  : ""
              }`}
            >
              Бизда бор:{" "}
              <span>
                {FormatNum(mainArr.vse <= 0 ? "0" : mainArr.vse)} {praductPtype}
              </span>
            </div>
            <div className="price">
              <span>{FormatNum(Floor2(praductPrice))} сўм </span>/{" "}
              {praductPtype}
            </div>
          </div>
          <div className="btns">
            <div className={`text-bar ${proList[praductID] > 0 && "active"}`}>
              <div
                className="btn-plus"
                onClick={() => {
                  setProList((prev) => {
                    let obj = {
                      ...proList,
                      [praductID]: prev[praductID] + 1,
                    };
                    localStorage.setItem("prolist", JSON.stringify(obj));
                    return obj;
                  });
                }}
              >
                +
              </div>
              <input
                type="number"
                onChange={(a) => {
                  setProList({
                    ...proList,
                    [praductID]: Number(a.target.value),
                  });
                }}
                value={proList[praductID] ?? 0}
              />
              <div
                className="btn-minus"
                onClick={() => {
                  setProList((prev) => {
                    let obj = {
                      ...proList,
                      [praductID]: prev[praductID] - 1,
                    };
                    localStorage.setItem("prolist", JSON.stringify(obj));
                    return obj;
                  });
                }}
              >
                -
              </div>
            </div>
            <button
              onClick={() => {
                if (proList[praductID] > 0) {
                  let arr = { ...proList };
                  delete arr[praductID];
                  setProList(arr);
                  localStorage.setItem("prolist", JSON.stringify(arr));
                } else {
                  setProList((p) => {
                    let obj = {
                      ...proList,
                      [praductID]: 1,
                    };
                    localStorage.setItem("prolist", JSON.stringify(obj));
                    return obj;
                  });
                }
              }}
            >
              {proList[praductID] > 0 ? (
                <i className="bx bxs-trash"></i>
              ) : (
                <i className="bx bx-store-alt"></i>
              )}
            </button>
          </div>
        </>
      </div>
    </div>
  );
}
