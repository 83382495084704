import { useState } from "react";
import "./Market.css";
import { Card } from "../../Components/Card/Card";
// import Swiper from "swiper";
import "swiper/css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

export function Market(props) {
  let [select, setSelect] = useState(null);
  let [fonUrl, setFonUrl] = useState([]);
  let [pname, setPname] = useState("");
  let [pdecs, setPdecs] = useState("");
  let [bar, setBar] = useState(false);
  let {
    setProList,
    proList,
    praducts,
    page,
    pageCount,
    newProList,
    status,
    setNewProList,
    setStatus,
    floor2,
  } = props;

  let matn = {
    start: "Сиз хали буюртмани якунламадингиз!",
    check: "Сиз хали буюртмани якунламадингиз!",
    checking: "Сизнинг буюртмагиз хали тастикланмади!",
    eror: "Сиз хали буюртмани якунламадингиз!",
    accect: "Малумотларни сакладингизми?",
  };
  return (
    <div className="market_box">
      {newProList && (
        <div
          className="resBoxProList"
          onClick={() => {
            if (Object.keys(proList).length > 0) {
              // setProList([]);
              if (!bar) {
                setBar(true);
                setTimeout(() => {
                  setBar(false);
                }, "3000");
              }
            }
          }}
        >
          <div className={`childPage ${bar ? "hideChildPage" : ""}`}>
            <h1>{matn[status]}</h1>
            <div className="spans">
              <span
                onClick={() => {
                  if (status === "accect") {
                    setProList([]);
                    setNewProList(false);
                    setStatus("start");
                  } else {
                    setBar(false);
                  }
                }}
              >
                Ха
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="market row no-scroll">
        <div
          className={`imagefone ${select !== null && "active"}`}
          onDoubleClick={() => {
            setSelect(null);
          }}
        >
          <div className="imagefone-card">
            <div className="close-imagefone" onClick={() => setSelect(null)}>
              <i className="bx bx-x bx-lg  bx-burst-hover"></i>
            </div>
            <Swiper spaceBetween={50} slidesPerView={1}>
              {fonUrl.map(
                (a, i) =>
                  i !== 0 && (
                    <SwiperSlide key={i}>
                      <img src={a} alt="logo" />
                    </SwiperSlide>
                  )
              )}
            </Swiper>
            <div className="text-info">
              <div className="text-name">{pname}</div>
              <div className="text-decs">{pdecs}</div>
            </div>
          </div>
        </div>

        {props.load
          ? praducts.map((arr) => (
              <Card
                key={arr.pid}
                mainArr={arr}
                select={select}
                setSelect={setSelect}
                setFonUrl={setFonUrl}
                setPname={setPname}
                setPdecs={setPdecs}
                setProList={setProList}
                proList={proList}
                shaxs={props.shaxs}
                floor2={floor2}
              />
            ))
          : props.none
          ? "none"
          : "Loading"}
      </div>
      <div className="pages">
        <div
          className="btn-prev"
          onClick={() => {
            props.setLoad(false);
            props.setPage((prev) =>
              Number(prev) > 0 ? Number(prev) - 1 : pageCount
            );
          }}
        >
          <i className="bx bxs-left-arrow bx-sm"></i>
        </div>
        <span>{Number(page) + 1}</span>
        <div
          className="btn-next"
          onClick={() => {
            props.setLoad(false);
            props.setPage((prev) =>
              Number(prev) < pageCount ? Number(prev) + 1 : 0
            );
          }}
        >
          <i className="bx bxs-right-arrow bx-sm"></i>
        </div>
      </div>
    </div>
  );
}
