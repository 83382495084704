import { useEffect, useState } from "react";
import { MenuBuilder } from "../../Components/menuBuilder/MenuBulder";
import "./LeftMenu.css";

export function LeftMenu(props) {
  let [none, setNone] = useState(false);
  let [noneMark, setMarkNone] = useState(false);
  let [catg, setCatgs] = useState([]);
  let [marks, setMarks] = useState([]);
  let [markOpen, setMarkOpen] = useState(false);
  let [catgOpen, setCatgOpen] = useState(false);
  useEffect(() => {
    fetch("https://api.xorazmelektromarket.uz?catgs=all")
      .then((response) => response.json())
      .then((json) => {
        if (json.erorcode === 404) {
          setNone(true);
        } else {
          setCatgs(json);
        }
      });

    fetch("https://api.xorazmelektromarket.uz?catgs=mark")
      .then((response) => response.json())
      .then((json) => {
        if (json.erorcode === 404) {
          setMarkNone(true);
        } else {
          setMarks(json);
        }
      });
  }, []);

  return (
    <div className={`leftmenu ${props.leftmenu && "open"}`}>
      <div className="stccky-left">
        <div
          className={`all-btn ${props.catg === "all" && "active"}`}
          onClick={() => {
            props.setCatg("all");
            props.setMark("all");
            props.setPage("0");
            props.setGoSearch(false);
          }}
        >
          Барча товарлар
        </div>
        {!noneMark && (
          <div className="menubuilder  w100">
            <button
              className="markClass"
              onClick={() => {
                setMarkOpen((prev) => !prev);
              }}
            >
              <span>Марка & Бренд</span>
              <i className={`bx bx-chevron-down ${markOpen && "active"}`}></i>
              <br />
              {props.mark !== "all" && (
                <span className="leftMarkName">{props.mark}</span>
              )}
            </button>
            <ul className={`acardion sc ${markOpen && "active"}`}>
              {marks.map((a, i) => (
                <li key={i}>
                  <div
                    className={`marks-item ${
                      props.mark === a.mainName && "select"
                    }`}
                    onClick={() => {
                      props.setMark(a.mainName);
                      props.setPage("0");
                    }}
                  >
                    {a.mainName}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="catgOpenBtn" onClick={() => setCatgOpen((a) => !a)}>
          Катигориялар
        </div>
      </div>
      {none ? (
        "none"
      ) : (
        <div className="catgNames">
          <div className={`catgResult ${catgOpen && "openCatg"}`}>
            {catg.map((arr, i) => (
              <MenuBuilder
                listId={i}
                key={arr.id}
                acKey={arr.mainName}
                acArrNames={arr.catgNames}
                acArrKeys={arr.catgKeys}
                setCatg={props.setCatg}
                catg={props.catg}
                setLoad={props.setLoad}
                setGoSearch={props.setGoSearch}
                setPage={props.setPage}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
