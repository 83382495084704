import { useState } from "react";
import "./GetInfo.css";

export function GetInfo(props) {
  let [sotib, setSotib] = useState("");
  let [manzil, setManzil] = useState("");
  let [stir, setStir] = useState("");
  let [kks, setKks] = useState("");
  let [xisob, setXisob] = useState("");
  let [mfo, setMfo] = useState("");
  let [raxbar, setRaxbar] = useState("");
  let [bugalter, setBugalter] = useState("");
  let [phone, setPhone] = useState("");
  let [name, setName] = useState("");
  let [mes, setMes] = useState(false);

  let { setShaxs, setUserInfo } = props;
  function Searche2(arr, term, searche = "phone") {
    if (term.lenght === 0) {
      return arr;
    }
    return arr.filter((item) => item[searche].indexOf(term) > -1);
  }

  function setClient(item, type = "phone") {
    fetch("https://api.xorazmelektromarket.uz/?" + type + "=" + item)
      .then((response) => response.json())
      .then((json) => {
        let massiv = [
          json.contrName,
          json.phone,
          json.contrName,
          json.adress,
          json.contrInn,
          json.kks,
          json.bank,
          json.mfo,
          json.raxbar,
          json.bugalter,
          json.baseID,
        ];
        setUserInfo(massiv);
        localStorage.setItem("userinfo", massiv);
        setShaxs(json.contrType === "0");
        localStorage.setItem("shaxs", json.contrType === "0" ? "jsh" : "ysh");
      });
  }

  let allState = [
    name,
    phone,
    sotib,
    manzil,
    stir,
    kks,
    xisob,
    mfo,
    raxbar,
    bugalter,
  ];

  let { phones } = props;

  let tekshir = props.shaxs
    ? name === "" || phone === ""
    : sotib === "" || manzil === "" || stir === "";
  return (
    <>
      <div className="getInfo">
        <div
          className="close-btn"
          onClick={() => {
            props.setGetInfo("getType");
          }}
        ></div>
        <div className={`eror ${mes && "on"}`}>
          Сиз барча малумотни киритмадингиз !
        </div>
        <div className="header">
          <h1>Шахс хакида малумотлар:</h1>
        </div>
        {props.shaxs ? (
          <div className="forms">
            <div
              className={`local reletive ${!phone && "active"} ${
                phone === "res" && "anime"
              }`}
            >
              <span className="text">Телефон ракам : </span>
              <input
                type="number"
                onChange={(a) => {
                  setPhone(a.target.value);
                }}
              />
              <div className={`phoneList ${phone.length > 0 && "view"}`}>
                <ul>
                  {Searche2(phones, phone).map((a, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        setClient(a.phone);
                      }}
                    >
                      {a.phone}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              className={`local ${!name && "active"} ${
                name === "res" && "anime"
              }`}
            >
              <span className="text">Исм Фамилия: </span>
              <input
                type="text"
                onChange={(a) => {
                  setName(a.target.value);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="forms">
            <div
              className={`local reletive ${!stir && "active"} ${
                stir === "res" && "anime"
              }`}
            >
              <span className="text">СТИР: </span>
              <input
                type="number"
                onChange={(a) => {
                  setStir(a.target.value);
                }}
              />
              <div className={`phoneList ${stir.length > 0 && "view"}`}>
                <ul>
                  {Searche2(phones, stir, "contrInn").map((a, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        setClient(a.contrInn, "inn");
                      }}
                    >
                      {a.contrInn}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              className={`local ${!phone && "active"} ${
                phone === "res" && "anime"
              }`}
            >
              <span className="text">Мурожаат учун телефон ракам : </span>
              <input
                type="number"
                onChange={(a) => {
                  setPhone(a.target.value);
                }}
              />
            </div>
            <div
              className={`local ${!sotib && "active"} ${
                sotib === "res" && "anime"
              }`}
            >
              <span className="text">Сотиб олувчи: </span>
              <input
                type="text"
                onChange={(a) => {
                  setSotib(a.target.value);
                }}
              />
            </div>
            <div
              className={`local ${!manzil && "active"} ${
                manzil === "res" && "anime"
              }`}
            >
              <span className="text">Манзил: </span>
              <input
                type="text"
                onChange={(a) => {
                  setManzil(a.target.value);
                }}
              />
            </div>

            <div className={`local ${kks === "res" && "anime"}`}>
              <span className="text">
                ККС толовчининг руйхатдан утиш коди:{" "}
              </span>
              <input
                type="number"
                onChange={(a) => {
                  setKks(a.target.value);
                }}
              />
            </div>
            <div className={`local  ${xisob === "res" && "anime"}`}>
              <span className="text">Банк хисоб раками: </span>
              <input
                type="number"
                onChange={(a) => {
                  setXisob(a.target.value);
                }}
              />
            </div>
            <div className={`local ${mfo === "res" && "anime"}`}>
              <span className="text">Банк МФО: </span>
              <input
                type="number"
                onChange={(a) => {
                  setMfo(a.target.value);
                }}
              />
            </div>
            <div className={`local ${raxbar === "res" && "anime"}`}>
              <span className="text">Рахбар: </span>
              <input
                type="text"
                onChange={(a) => {
                  setRaxbar(a.target.value);
                }}
              />
            </div>
            <div className={`local ${bugalter === "res" && "anime"}`}>
              <span className="text">Бош бугалтер: </span>
              <input
                type="text"
                onChange={(a) => {
                  setBugalter(a.target.value);
                }}
              />
            </div>
          </div>
        )}
        <div
          className="send-btn"
          onClick={() => {
            if (tekshir) {
              setMes(true);
              setTimeout(() => {
                setMes(false);
              }, 2000);
            } else {
              setMes(false);
              // allState[10]=props.savaDatesForDB("save", allState, props.shaxs);
              props
                .savaDatesForDB("save", allState, props.shaxs)
                .then((res) => res.json())
                .then((result) => {
                  allState[10] = result[0];
                  props.setUserInfo(allState);
                  localStorage.setItem("userinfo", allState);
                });
              props.setGetInfo("");
            }
          }}
        >
          Саклаш
        </div>
      </div>
    </>
  );
}
