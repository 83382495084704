function formatDate(text) {
  return text.toString().length === 1 ? "0" + text : text;
}

function EditTime(time, type = 0) {
  let date = new Date(time);
  let res = "";
  if (type === 0) {
    res =
      formatDate(date.getDate()) +
      "." +
      formatDate(Number(date.getMonth()) + 1) +
      "." +
      date.getFullYear() +
      " " +
      formatDate(date.getHours()) +
      ":" +
      formatDate(date.getMinutes()) +
      ":" +
      formatDate(date.getSeconds());
  } else if (type === 1) {
    res =
      formatDate(date.getDate()) +
      "." +
      formatDate(Number(date.getMonth()) + 1) +
      " " +
      formatDate(date.getHours()) +
      ":" +
      formatDate(date.getMinutes());
  }
  return res;
}

export { EditTime };
