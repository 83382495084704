import { Pricing } from "../../functions/mathFunctions";
import "./Document.css";
import html2canvas from "html2canvas";
export function Document(props) {
  let { proList, userInfo, setDocsOpen, praducts2 } = props;

  let result = Pricing(praducts2, proList);

  return (
    <>
      <div
        className="document"
        style={{
          justifyContent:
            document.querySelector("body").offsetWidth > 800
              ? "center"
              : "flex-start",
        }}
      >
        <div
          className="download-docs-btn"
          onClick={() => {
            html2canvas(document.querySelector(".docs-text-content")).then(
              (canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                const a = document.createElement("a");
                a.setAttribute("download", "image.png");
                a.setAttribute("href", dataURL);
                a.click();
              }
            );
          }}
        >
          Юклаб олиш
        </div>
        <div
          className="close-btn-fordocument "
          onClick={() => {
            setDocsOpen((prev) => !prev);
          }}
        >
          Бўлимни ёпиш
        </div>
        <div className="docs-user-box no-scroll">
          <div className="docs-text-content">
            <div className="top">
              <h1>Хисоб варак</h1>
            </div>
            <div className="bottom">
              <ul className="cons">
                <li>
                  <span className="bold">Етказиб берувчи:</span>
                  <span className="decs">"XORAZM KABEL SAVDA" MCHJ</span>
                </li>
                <li>
                  <span className="bold">Манзил:</span>
                  <span className="decs">171-КВАРТАЛ</span>
                </li>
                <li>
                  <span className="bold">
                    Етказиб берувчининг идентификатсия раками (СТИР):
                  </span>
                  <span className="decs">3038335270</span>
                </li>
                <li>
                  <span className="bold">
                    ККС тўловчининг рўйхатдан ўтиш коди:
                  </span>
                  <span className="decs">333040034255 (гувохнома фаол)</span>
                </li>
                <li>
                  <span className="bold">Банк хисобварағи:</span>
                  <span className="decs">2020800000594114001</span>
                </li>
                <li>
                  <span className="bold">Банк МФО:</span>
                  <span className="decs">00569</span>
                </li>
              </ul>
              <ul className="klient">
                <li>
                  <span className="bold">Сотиб олувчи:</span>
                  <span className="decs">{userInfo[2]}</span>
                </li>
                <li>
                  <span className="bold">Манзил:</span>
                  <span className="decs">{userInfo[3]}</span>
                </li>
                <li>
                  <span className="bold">
                    сотиб олувчининг идентификатсия раками (СТИР):
                  </span>
                  <span className="decs">{userInfo[4]}</span>
                </li>
                <li>
                  <span className="bold">
                    ККС тўловчининг рўйхатдан ўтиш коди:
                  </span>
                  <span className="decs">
                    {userInfo[5] ? userInfo[5] : "-"}
                  </span>
                </li>
                <li>
                  <span className="bold">Банк хисобварағи:</span>
                  <span className="decs">{userInfo[6]}</span>
                </li>
                <li>
                  <span className="bold">Банк МФО:</span>
                  <span className="decs">{userInfo[7]}</span>
                </li>
              </ul>
            </div>
            <table>
              <thead>
                <tr className="table-bold">
                  <td rowSpan={2}>№</td>
                  <td rowSpan={2}>Махсулот ёки хизматга изох</td>
                  <td rowSpan={2}>Ўлчов бирлиги</td>
                  <td rowSpan={2}>Микдори</td>
                  <td rowSpan={2}>Нархи</td>
                  <td rowSpan={2}>Етказиб бериш киймати</td>
                  <td colSpan={2}>ККС</td>
                  <td rowSpan={2}>
                    ККСни исобга олган холда етказиб бериш киймати
                  </td>
                </tr>
                <tr className="table-bold">
                  <td>Ставка</td>
                  <td>Сумма</td>
                </tr>
              </thead>
              <tbody>
                {Object.entries(result.prod).map((a, i) => {
                  let { price, count, qqs, singlePrice, ourQqs, name, ptype } =
                    a[1];
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td className="text-al">{name}</td>
                      <td>{ptype}</td>
                      <td>{count}</td>
                      <td>{singlePrice}</td>
                      <td>{price}</td>
                      <td>8</td>
                      <td>{ourQqs}</td>
                      <td>{qqs}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5} className="table-bold text-ar">
                    Жами:
                  </td>
                  <td>{result.price}</td>
                  <td>x</td>
                  <td>{result.ourQqs}</td>
                  <td>{result.qqs}</td>
                </tr>
              </tfoot>
            </table>
            <div className="footer-docs">
              <div className="footer-left">
                <ul>
                  <li>
                    <b>Рахбар:</b>
                    <span> Рахимов Хамза розметович</span>
                  </li>
                  <li>
                    <b>Бош бугалтер: </b>
                    <span>садуллаев умид ражабович</span>
                  </li>
                </ul>
              </div>
              <div className="footer-right">
                <ul>
                  <li>
                    <b>Рахбар:</b>
                    <span> {userInfo[8]}</span>
                  </li>
                  <li>
                    <b>Бош бугалтер: </b>
                    <span>{userInfo[9] === "" ? "-" : userInfo[9]}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
