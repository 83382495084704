import { useEffect, useState } from "react";
import "./EditUser.css";

export function EditUser(props) {
  let [sotib, setSotib] = useState("");
  let [manzil, setManzil] = useState("");
  let [stir, setStir] = useState("");
  let [kks, setKks] = useState("");
  let [xisob, setXisob] = useState("");
  let [mfo, setMfo] = useState("");
  let [raxbar, setRaxbar] = useState("");
  let [bugalter, setBugalter] = useState("");
  let [phone, setPhone] = useState("");
  let [name, setName] = useState("");
  let [baseID, setBaseID] = useState("");
  let [mes, setMes] = useState(false);

  let tekshir = props.shaxs
    ? name === "" || phone === ""
    : sotib === "" || manzil === "" || stir === "";

  let allState = [
    name,
    phone,
    sotib,
    manzil,
    stir,
    kks,
    xisob,
    mfo,
    raxbar,
    bugalter,
    baseID,
  ];
  let { userInfo, setOffers } = props;
  useEffect(() => {
    setName(userInfo[0]);
    setPhone(userInfo[1]);
    setSotib(userInfo[2]);
    setManzil(userInfo[3]);
    setStir(userInfo[4]);
    setKks(userInfo[5]);
    setXisob(userInfo[6]);
    setMfo(userInfo[7]);
    setRaxbar(userInfo[8]);
    setBugalter(userInfo[9]);
    setBaseID(userInfo[10]);
  }, []);
  return (
    <div className="edituser">
      <div className={`eror ${mes && "on"}`}>
        Сиз барча малумотни киритмадингиз !
      </div>
      <div
        className="close-btn"
        onClick={() => {
          props.setEditOpen((prev) => !prev);
        }}
      ></div>
      <h1>Сизнинг малумотларингиз</h1>
      {props.shaxs ? (
        <div className="forms">
          <div className={`local ${!name && "active"}`}>
            <span className="text">Исм Фамилия: </span>
            <input
              type="text"
              value={name}
              onChange={(a) => {
                setName(a.target.value);
              }}
            />
          </div>
          <div className={`local ${!phone && "active"}`}>
            <span className="text">Телефон ракам : </span>
            <input
              type="text"
              value={phone}
              onChange={(a) => {
                setPhone(a.target.value);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="forms">
          <div className={`local`}>
            <span className="text">Мурожаат учун телефон ракам : </span>
            <input
              type="number"
              placeholder="+998 99 999 99 99"
              value={phone}
              onChange={(a) => {
                setPhone(a.target.value);
              }}
            />
          </div>
          <div className={`local ${!sotib && "active"}`}>
            <span className="text">Сотиб олувчи: </span>
            <input
              type="text"
              value={sotib}
              onChange={(a) => {
                setSotib(a.target.value);
              }}
            />
          </div>
          <div className={`local ${!manzil && "active"}`}>
            <span className="text">Манзил: </span>
            <input
              type="text"
              value={manzil}
              onChange={(a) => {
                setManzil(a.target.value);
              }}
            />
          </div>
          <div className={`local ${!stir && "active"}`}>
            <span className="text">
              Сотиб олувчининг иденфикатсия раками (СТИР):{" "}
            </span>
            <input
              type="number"
              value={stir}
              onChange={(a) => {
                setStir(a.target.value);
              }}
            />
          </div>
          <div className={`local `}>
            <span className="text">ККС толовчининг руйхатдан утиш коди: </span>
            <input
              value={kks}
              type="number"
              onChange={(a) => {
                setKks(a.target.value);
              }}
            />
          </div>
          <div className={`local`}>
            <span className="text">Банк хисоб раками: </span>
            <input
              value={xisob}
              type="number"
              onChange={(a) => {
                setXisob(a.target.value);
              }}
            />
          </div>
          <div className={`local`}>
            <span className="text">Банк МФО: </span>
            <input
              value={mfo}
              type="number"
              onChange={(a) => {
                setMfo(a.target.value);
              }}
            />
          </div>
          <div className={`local `}>
            <span className="text">Рахбар: </span>
            <input
              type="text"
              value={raxbar}
              onChange={(a) => {
                setRaxbar(a.target.value);
              }}
            />
          </div>
          <div className={`local `}>
            <span className="text">Бош бугалтер: </span>
            <input
              value={bugalter}
              type="text"
              onChange={(a) => {
                setBugalter(a.target.value);
              }}
            />
          </div>
        </div>
      )}

      <div
        className="ourOffers"
        onClick={() => {
          setOffers((a) => !a);
          props.setEditOpen((prev) => !prev);
        }}
      >
        Буюртмаларим
      </div>
      <div className="btns-box">
        <div
          className="send-btn"
          onClick={() => {
            if (tekshir) {
              setMes(true);
              setTimeout(() => {
                setMes(false);
              }, 2000);
            } else {
              setMes(false);
              props.setEditOpen((prev) => !prev);
              props
                .savaDatesForDB("edit", allState, props.shaxs)
                .then((res) => res.json())
                .then((result) => {
                  allState[10] = result;
                  props.setUserInfo(allState);
                  localStorage.setItem("userinfo", allState);
                });
            }
          }}
        >
          Саклаш
        </div>
        <div
          className="send-btn"
          onClick={() => {
            props.resetDates();
            localStorage.clear();
          }}
        >
          Тозалаш
        </div>
      </div>
    </div>
  );
}
