// import data from "./praducts.json";

let globalQQS = 0.92;

function Floor2(sum) {
  let res = sum / 1000;
  res = Math.round(res);
  return res * 1000;
}

function FormatNum(num, str = " ") {
  return Number(num).toLocaleString("en-US").replaceAll(",", str);
}

function UpPrice(sum) {
  return Floor2(Math.round(Number(sum) / globalQQS));
}
function Pricing(praducts2, keys) {
  let id = Object.keys(keys);
  let products = {};
  let allQqs = 0;
  let allPrice = 0;
  let ourQqsAll = 0;
  let count2 = 0;
  id.map((key) => {
    let count = keys[key],
      singlePrice = Number(praducts2[key]["price"]),
      price = singlePrice * count,
      singleQqs = Floor2(singlePrice / globalQQS),
      qqs = singleQqs * count,
      ourQqs = qqs - price;
    allPrice += Number(price);
    ourQqsAll += ourQqs;
    allQqs += Number(qqs);
    count2 += Number(count);
    products = {
      ...products,
      [key]: {
        price: FormatNum(Floor2(price), " "),
        singlePrice: FormatNum(Floor2(singlePrice), " "),
        qqs: FormatNum(Floor2(qqs), " "),
        singleQqs: FormatNum(Floor2(singleQqs), " "),
        count: FormatNum(count, " "),
        ourQqs: FormatNum(Floor2(ourQqs), " "),
        name: praducts2[key]["name"],
        ptype: praducts2[key]["ptype"],
      },
    };
  });

  return {
    prod: products,
    qqs: FormatNum(Floor2(allQqs), " "),
    price: FormatNum(Floor2(allPrice), " "),
    ourQqs: FormatNum(Floor2(ourQqsAll), " "),
    count: FormatNum(count2, "  "),
  };
}

export { FormatNum, UpPrice, Floor2, Pricing };
