import { useState } from "react";
import "./TopMenu.css";

export function TopMenu(props) {
  let [ourSearche, setOurSearche] = useState();
  let { allprice, allpraduct, proList, userInfo, shaxs } = props;
  const formatUSAndInternationalNumber = (phone) =>
    phone
      .replace(/\D/g, "")
      .replace(
        /(\d*)(\d{2})(\d{3})(\d{4})$/,
        (s, a, b, c, d) => `+${a} (${b}) ${c}-${d}`
      )
      .replace(/\+(1\b|\s)\s*/, "");

  return (
    <div className="topmenu">
      <nav className="row justify-beetwen container align-center">
        <div className="logo col-2 col-4-md col-3-sm col-4-xs">
          <img src="./image/logo.png" alt="logo" />
          <h4 className="logo-text">
            <span>ХЕ</span> Маркет
          </h4>
        </div>
        <div className="searche col-6 col-5-md ">
          <label className="input row">
            <input
              type="text"
              className="text col-10-lg col-8-md col-9-sm col-9-xs"
              value={ourSearche}
              onChange={(a) => {
                setOurSearche(a.target.value);
              }}
              onKeyDown={(a) => {
                if (a.code === "NumpadEnter" || a.code === "Enter") {
                  props.setGoSearch(true);
                  props.setSearche(ourSearche);
                  props.setCatg("");
                  props.setPage("0");
                  setOurSearche("");
                }
              }}
              disabled={userInfo[0] === undefined}
            />
            <div
              className="a btn col-2-lg col-4-md col-3-sm col-3-xs"
              onClick={() => {
                props.setGoSearch(true);
                props.setSearche(ourSearche);
                props.setCatg("");
                props.setPage("0");
                setOurSearche("");
              }}
            >
              <p></p>
              <i className="bx bx-search"></i> Қидириш
            </div>
          </label>
        </div>
        <ul className="menu justify-end col-2 col-3-md col-2-sm row">
          <li className="list-items">
            <div
              className="a cabine"
              onClick={() => {
                props.setEditOpen((prev) => !prev);
              }}
            >
              <i className="bx bx-user bx-md"></i>
              <span>Кабинет</span>
            </div>
          </li>
        </ul>
      </nav>
      <div className="border"></div>
      <div className="info-agets-div">
        <div className="info-agents">
          <div className="name">
            Сотиб олувчи: <span>{userInfo[0]}</span>
          </div>
          <div className="phone">
            Телефон раками:{" "}
            <span>
              {userInfo[1] && formatUSAndInternationalNumber(userInfo[1])}
            </span>
          </div>

          {!shaxs && (
            <div className="inn">
              ИНН раками: <span>{userInfo[4]}</span>
            </div>
          )}
        </div>
      </div>
      <section className="catigories">
        <div className="container">
          <ul className="row justify-around align-center">
            <div
              className="open-close-left-menu"
              onClick={() => {
                props.setLeftmenu((prev) => !prev);
                props.setRightmenu(false);
              }}
            >
              <i
                className={`bx bx-chevron-down ${
                  props.leftmenu && "active"
                } rotate1`}
              ></i>
            </div>
            <div
              className="openMainMenu"
              onClick={() => {
                props.setCatg("all");
                props.setMark("all");
                props.setPage("0");
                props.setGoSearch(false);
              }}
            >
              Асосий бўлмни очиш
            </div>
            <div
              className="openMainMenu"
              onClick={() => {
                props.setCatg("pupul");
                props.setMark("all");
                props.setPage("0");
                props.setGoSearch(false);
              }}
            >
              Кўп сотилганлар
            </div>
            {props.allpraduct > 0 && (
              <div className="info-bar-menu">
                <h3>
                  Умумий сони: <span>{allpraduct}</span>
                </h3>
                <h3>
                  Махсулотлар тури: <span>{Object.keys(proList).length}</span>
                </h3>
                <h3>
                  Умумий сумма:{" "}
                  <span>{allprice.toLocaleString("en-US")} сўм </span>
                </h3>
              </div>
            )}
            <div
              className="open-close-right-menu"
              onClick={() => {
                props.setRightmenu((prev) => !prev);
                props.setLeftmenu(false);
              }}
            >
              <div className="count-allpraduct">
                <span className={`${props.allpraduct > 0 && "view"}`}>
                  {props.allpraduct}
                </span>
                <i className="bx bx-basket bx-md"></i>
              </div>
              <i
                className={`bx bx-chevron-down ${
                  props.rightmenu && "active"
                } rotate2`}
              ></i>
            </div>
          </ul>
        </div>
      </section>
    </div>
  );
}
