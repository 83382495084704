import html2canvas from "html2canvas";
import "./GetUser.css";
import { Pricing } from "../../functions/mathFunctions";
import { EditTime } from "../../functions/dataTime";

export function GetUser(props) {
  let { proList, userInfo, buyTime, praducts2 } = props;
  let result = Pricing(praducts2, proList);
  return (
    <div
      className="document"
      style={{
        justifyContent:
          document.querySelector("body").offsetWidth > 800
            ? "center"
            : "flex-start",
      }}
    >
      <div
        className="download-docs-btn"
        onClick={() => {
          html2canvas(document.querySelector(".docs-text-content-user")).then(
            (canvas) => {
              let dataURL = canvas.toDataURL("image/png");
              const a = document.createElement("a");
              a.setAttribute("download", "image.png");
              a.setAttribute("href", dataURL);
              a.click();
            }
          );
        }}
      >
        Юклаб олиш
      </div>
      <div
        className="close-btn-fordocument "
        onClick={() => {
          props.setDocsOpen((prev) => !prev);
        }}
      >
        Бўлимни ёпиш
      </div>
      <div className="docs-user-box no-scroll">
        <div className="docs-text-content-user">
          <div className="cheque-nav">
            <span>
              Продавец: <b>Online</b>
            </span>
            <span>{EditTime(buyTime)}</span>
          </div>
          {Object.entries(result.prod).map((a, i) => {
            let { price, count, singlePrice, name, ptype } = a[1];
            return (
              <div key={i} className="cheque-element">
                <div className="element-name">
                  <span>{i + 1}. </span>
                  <span>{name}</span>
                </div>
                <div className="element-price">
                  <span>- - -{count} X </span>
                  <span>{singlePrice} = </span>
                  <span>{price} UZS</span>
                </div>
              </div>
            );
          })}
          <ul className="cheq-footer">
            <li>
              <span className="info-ceq-footer">Сотиш нархи :</span>
              <span className="ceq-price">{result.price} UZS</span>
            </li>
            <li>
              <span className="info-ceq-footer">Скидка :</span>
              <span className="ceq-price">0 UZS</span>
            </li>
            <li className="big">
              <span className="info-ceq-footer">ТОЛОВ УЧУН :</span>
              <span className="ceq-price">{result.price} UZS</span>
            </li>
            <li>
              <span className="info-ceq-footer">Отказма :</span>
              <span className="ceq-price">{result.price} UZS</span>
            </li>
            <li className="info-client-footer">
              <span className="info-client">Харидор :</span>
              <span className="ceq-name">{userInfo[0]}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
